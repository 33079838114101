import React, {useContext, useEffect, useRef, useState} from 'react';
import Icon from '../Icon/Icon';
import Product from '../Product/Product';
import Lang from '../Lang/Lang';
import {
  ClientWidthContext,
  ModalContext, ModalDispatchContext, SearchContext, SearchDispatchContext,
} from '../Context/ApplicationContextProvider';
import ProductInSearch from '../Product/ProductInSearch';
import {
  apiBaseUrl,
  axiosInstance, esputnikTracker,
  hoverUnderlineClass,
  lang,
  underlineClass,
} from '../../common/baseData';
import Cookies from 'js-cookie';

function SearchResult(props) {
  const {
    additionalClass,
    topPosition,
    inPageMobile,
  } = {...props};

  const {
    query,
    start,
    direct,
    productList,
    categoryList,
    historyList,
    promotedProducts,
    potentialQueryList,
    total,
    totalLink,
  } = useContext(SearchContext);
  const dispatchSearch = useContext(SearchDispatchContext);

  const clientWidth = useContext(ClientWidthContext);

  const isMobile = clientWidth < 1024;

  const mobileClassName = `flex flex-col ${inPageMobile ? '' : 'fixed left-0 right-0'} z-30 min-h-96 w-full bg-white gap-5 overflow-y-scroll`;
  const desktopClassName = `flex absolute top-0 z-30 left-0 min-h-96 h-auto right-0 w-full bg-white pt-3 pb-8 px-5`;

  const promotedTitleMobileClassName = `text-[16px] leading-[24px] text-grey-90 font-medium`;
  const promotedTitleDesktopClassName = `text-[14px] leading-[21px] text-grey-80`;

  const promotedProductMobileClassName = `min-w-[171px] w-[171px] p-4`;
  const promotedProductDesktopClassName = `min-w-[240px] w-[240px] px-10 py-6`;

  const mobileTop = `${topPosition}px`;

  const searchPage = `${lang === 'ru' ? '/' : `${lang}/`}search/`;

  // признак показа продвигаемых товаров
  const showPromotedProducts = Array.isArray(promotedProducts) &&
    promotedProducts.length > 0;

  /**
   * событие смены прискового запроса
   */
  useEffect(() => {
    if (query.length <= 1) return;
    search(query);
  }, [query]);

  /**
   * Executes a search query and updates the state with the search results.
   *
   * @param {string} query - The search query to be executed.
   * @return {void}
   */
  function search(query) {
    axiosInstance.post(`${apiBaseUrl}search/query`, {
      'query': query,
    }).then(response => response.data).then(data => {
      // direct logic
      let total_link = '';
      let products = [];
      let catogories = [];
      let potential_query_List = [];
      let count = 0;
      let direct = null;

      if (data.hasOwnProperty('ITEMS')) {
        total_link = searchPage + '?q=' + query;
        products = data['ITEMS'];
        // esputnik search event
        esputnikTracker.SearchRequest(query,
          Array.isArray(data.ITEMS) && data.ITEMS.length ? 1 : 0);
      }
      if (data.hasOwnProperty('SECTIONS')) catogories = data['SECTIONS'];
      if (data.hasOwnProperty(
        'POTENTIAL_QUERY_LIST')) potential_query_List = data['POTENTIAL_QUERY_LIST'];
      if (data.hasOwnProperty('TOTAL')) count = data['TOTAL'];

      direct = data.hasOwnProperty('DIRECT') && data.DIRECT !== null &&
      data.DIRECT.length ?
        data.DIRECT :
        null;

      // обрезаем кол-во до 5 на mobile
      if (isMobile && Array.isArray(products) && products.length > 5) {
        products = products.slice(0, 5);
      }

      let payload = {
        'totalLink': total_link,
        'productList': products,
        'categoryList': catogories,
        'potentialQueryList': potential_query_List,
        'total': count,
        'direct': direct,
      };

      dispatchSearch({type: 'update', payload: payload});
    }).catch(reason => console.log(reason));
  }

  /**
   * удаление элемента истории поиска
   * @param query
   */
  function onRemoveHistoryItem(query) {
    axiosInstance.post(`${apiBaseUrl}search/removeHistoryEntry`,
      {'query': query}).
      then(response => response.data).
      then(response => {
        if (response.hasOwnProperty('HISTORY') &&
          Array.isArray(response.HISTORY))
          dispatchSearch(
            {type: 'update', payload: {'historyList': response.HISTORY}});
      }).
      catch(reason => console.log(reason));
  }

  /**
   * очистка всей истории поиска
   */
  function onClearHistory() {
    axiosInstance.post(`${apiBaseUrl}search/clearHistory`, {}).
      then(response => response.data).
      then(response => {
        if (response.hasOwnProperty('HISTORY') &&
          Array.isArray(response.HISTORY))
          dispatchSearch(
            {type: 'update', payload: {'historyList': response.HISTORY}});
      }).
      catch(reason => console.log(reason));
  }

  // признак показа левой части
  const showLeftSide = (Array.isArray(historyList) && historyList.length > 0) ||
    (Array.isArray(categoryList) && categoryList.length > 0) ||
    (Array.isArray(potentialQueryList) && potentialQueryList.length > 0);

  const rightSideWidth = showLeftSide && !isMobile
    ? 'w-[calc(100%-315px)]'
    : 'w-full';

  useEffect(() => {
    let calculated = clientWidth < 1024;
    if (isMobile !== calculated) setIsMobile(calculated);
  }, [clientWidth]);

  return (
    <div id="search-result"
         className={`${inPageMobile ? 'fixed top-0 bottom-0 left-0 right-0' : ''} relative`}>
      <div className={`${isMobile
        ? `${additionalClass} ${mobileClassName}`
        : desktopClassName} overscroll-y-none`}
           style={{
             height: isMobile ? `calc(100dvh - ${mobileTop})` : '',
             top: isMobile ? mobileTop : '',
           }}>

        {
          showLeftSide && (
            <div className={`${!isMobile
              ? 'w-[290px] mr-5'
              : ''} flex flex-col shrink-0 px-5`}>
              {/** потенциальные запросы **/
                Array.isArray(potentialQueryList) &&
                potentialQueryList.length > 0 && (
                  <div className={`flex flex-col gap-3 mt-1 desktop:mt-0 mb-5 `}>
                    <span className={'text-[14px] leading-[21px] text-grey-80'}>
                      {Lang.getMessage('maybeYouSearched')}
                    </span>
                    <ul className={`flex ${isMobile
                      ? 'w-full flex-wrap'
                      : 'flex-col'} gap-2.5`}>
                      {/** история поисковых запросов **/
                        potentialQueryList.map((suggestion, key) => {
                          return (
                            <li
                              className="flex justify-between items-center bg-grey-15 w-max py-1 px-2"
                              key={`suggestion-${key}`}>
                            <span
                              className="text-[13px] leading-[20px] text-black cursor-pointer search-suggestion"
                              dangerouslySetInnerHTML={{__html: suggestion}}
                              onClick={event => {
                                let clearedSuggestion = suggestion.replace(
                                  /<\/?[^>]+(>|$)/ig, '');
                                console.info('click on suggestion: ',
                                  suggestion, clearedSuggestion);
                                dispatchSearch({
                                  type: 'setQuery',
                                  payload: clearedSuggestion,
                                });
                              }}/>
                            </li>
                          );
                        })
                      }
                    </ul>
                  </div>
                )
              }
              {/** история поисковых запросов **/
                Array.isArray(historyList) &&
                historyList.length > 0 && (
                  <div className={'flex flex-col gap-3'}>
                    <div className={`flex justify-between items-center`}>
                  <span className={'text-[14px] leading-[21px] text-grey-80'}>
                    {Lang.getMessage('searchHistory')}
                  </span>
                      <button
                        className={'text-[14px] leading-[21px] font-medium text-blue-primary bg-transparent underline border-0 p-0'}
                        onClick={
                          () => onClearHistory()
                        }>{Lang.getMessage('clear')}
                      </button>
                    </div>
                    <ul className={`flex flex-col gap-2.5`}>
                      {/** история поисковых запросов **/
                        historyList.map((query, key) => {
                          return (
                            <li className="flex justify-between items-center"
                                key={`history-item-${key}`}>
                              <span className={'flex gap-2.5 items-center'}>
                                <Icon name={'history-icon'}
                                      className="h-6 w-6"/>
                                <span
                                  className="text-[14px] leading-[21px] text-grey-90 cursor-pointer"
                                  onClick={event => {
                                    dispatchSearch({
                                      type: 'setQuery',
                                      payload: query,
                                    });
                                  }}>
                                  {query}
                                </span>
                              </span>
                              <Icon name={'close-icon'}
                                    className="h-4 w-4 cursor-pointer"
                                    onClick={() => onRemoveHistoryItem(query)}/>
                            </li>
                          );
                        })
                      }
                    </ul>
                  </div>
                )
              }
              {/** найденные категории **/
                Array.isArray(categoryList) &&
                categoryList.length > 0 && (
                  <div className={'flex flex-col gap-3 mt-4.5'}>
                <span className={'text-[14px] leading-[21px] text-grey-80'}>
                  {Lang.getMessage('popularCategories')}
                </span>
                    <ul className={`flex flex-col gap-2.5`}>
                      {
                        categoryList.map((category, key) => {
                          return (
                            <li key={`founded-category-${key}`}
                                className="flex justify-between items-center">
                              <a href={category.FILTER_PAGE_URL}
                                 className={`text-[14px] leading-[21px] text-grey-90 ${hoverUnderlineClass}`}>
                                {category.NAME}
                              </a>
                            </li>
                          );
                        })
                      }
                    </ul>
                  </div>
                )
              }
            </div>
          )
        }

        <div
          className={`${rightSideWidth} flex ${showPromotedProducts && !isMobile
            ? ''
            : 'flex-col'}`}>

          {/** список продвигаемых товаров **/
            query.length <= 0 && showPromotedProducts && (
              <div className={'flex flex-col gap-3 w-full'}>
                <span className={`${isMobile
                  ? promotedTitleMobileClassName
                  : promotedTitleDesktopClassName} ${isMobile ? 'pl-5' : ''}`}>
                  {Lang.getMessage('You might like it')}
                </span>
                <ul className={`w-full ${isMobile
                  ? 'px-5'
                  : 'px-4'} desktop:px-0 flex overflow-x-scroll scroll-smooth snap-x snap-mandatory overscroll-x-none invisible-scrollbar`}>
                  {
                    promotedProducts.map((item, index) => (
                      <Product {...item}
                               rootClassName={`flex flex-col shrink-0 ${isMobile
                                 ? promotedProductMobileClassName
                                 : promotedProductDesktopClassName} ${index ===
                               promotedProducts.length - 1 ? '' : 'border-r-0'}`}
                               key={`promoted-${item.ID}`}
                               itemKey={`promoted-${item.ID}`}/>
                    ))
                  }
                </ul>
              </div>
            )
          }

          {/** список найденных товаров **/
            Array.isArray(productList) && productList.length > 0 && (
              <div className={`flex flex-col ${isMobile ? 'px-5' : ''} w-full`}>
                <span className={'text-[14px] leading-[21px] text-grey-80'}>
                  {Lang.getMessage('Popular products')}
                </span>
                <ul
                  className={`desktop:h-[480px] w-full flex flex-col gap-1 overflow-y-scroll scroll-smooth snap-y snap-mandatory visible-scrollbar-y mt-3`}>
                  {
                    productList.map((item, index) => (
                      <ProductInSearch {...item}
                                       key={`founded-product-${item.ID}`}
                                       itemKey={`founded-product-${item.ID}`}/>
                    ))
                  }
                </ul>
                <div className={`${isMobile
                  ? 'pt-5 pb-10 border-0 border-solid border-t border-grey-30'
                  : 'mt-5'} flex items-center justify-center`}>
                  <a href={totalLink}
                     target={'_blank'}
                     className={`text-[15px] leading-[20px] text-primary-blue hover:underline-offset-2 hover:underline`}>
                    {Lang.getMessage('Show all products')} ({total})
                  </a>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}

export default SearchResult;