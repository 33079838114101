import React, {useContext, useEffect, useRef, useState} from 'react';
import Icon from '../Icon/Icon';
import {
  apiBaseUrl,
  axiosInstance,
  esputnikTracker,
  eventEmitter, hoverBtnClass,
  lang,
} from '../../common/baseData';
import SearchResult from './SearchResult';
import {
  ClientWidthContext,
  ModalContext, ModalDispatchContext, SearchContext, SearchDispatchContext,
} from '../Context/ApplicationContextProvider';
import {DebounceInput} from 'react-debounce-input';
import Lang from '../Lang/Lang';
import Cookies from 'js-cookie';

function SearchForm(props) {
  const {children, defineInputFocus, type} = {...props};
  const {
    query,
    start,
    direct,
    productList,
    categoryList,
    historyList,
    promotedProducts,
    potentialQueryList,
    total,
    totalLink,
  } = useContext(SearchContext);
  const dispatchSearch = useContext(SearchDispatchContext);
  const inputRef = useRef(null);
  const ref = useRef(null);
  const modals = useContext(ModalContext);
  const dispatchModal = useContext(ModalDispatchContext);
  const clientWidth = useContext(ClientWidthContext);
  const isMobile = clientWidth < 1024;
  const searchPage = `${lang === 'ru' ? '/' : `${lang}/`}search/`;

  /** Принудительная установка фокуса при определенном условии **/
  useEffect(() => {
    if (defineInputFocus && inputRef.current instanceof HTMLElement) {
      inputRef.current.focus();
    }
  }, [inputRef.current, defineInputFocus]);

  /**
   * событие смены прискового запроса
   */
  useEffect(() => {
    if (query.length <= 0) return;
    // сохраняем последнюю поисковую фразу
    Cookies.set('_ms_search_phase', query, {expires: 1});
  }, [query]);

  /**
   * событие клика / закрытие поиска
   */
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  /**
   * событие клика вне блока
   */
  function handleClickOutside(event) {
    if (clientWidth > 767 && ref.current &&
      !ref.current.contains(event.target)) {
      setSearchActive(false);
    }
  }

  const defaultClass = 'desktop:mx-12';
  const activeClass =  'desktop:ml-12';

  function onKeyUp(e) {
    if (e.code === 'Enter' && query.length) onSubmitQuery();
  }

  function onSubmitQuery() {
    if (direct !== undefined && direct.length)
      window.location.href = lang === 'ua' ?
        `/${lang}${direct}` :
        direct;
    else {
      if (total) {
        location.href = totalLink;
      } else location.href = `${searchPage}?q=${query}`;
    }
  }

  /**
   * установка активности поиска
   * @param active
   */
  function setSearchActive(active) {
    dispatchModal({type: active ? 'show' : 'hide', modalType: type});
    if (!active) dispatchSearch({type: 'clear'});
  }

  return (
    <div className={`relative w-full desktop:w-auto flex flex-col h-[45px] desktop:h-[56px] grow ${modals.includes('search') ? activeClass : defaultClass}`} ref={ref}>
      <form name="site-search" className={'w-full flex h-full'} data-testid="search-form">
        <span className="absolute left-3 flex justify-center items-center h-full">
          <Icon name={'search-icon'} className="h-4 w-4"/>
        </span>
        <DebounceInput
          value={query}
          inputRef={inputRef}
          enterKeyHint="search"
          minLength={2}
          debounceTimeout={500}
          type="text"
          autoComplete="off"
          placeholder={Lang.getMessage('search')}
          className={`w-full p-3 ${isMobile ? 'px-10' : 'pl-10'} h-full flex gap-3 border-grey-20 border border-solid text-[14px] leading-[21px] text-grey-90 desktop:border-r-0`}
          id="h-search-input"
          onChange={event => {
            dispatchSearch({type: 'setQuery', payload: event.target.value});
          }}
          onFocus={event => {
            event.stopPropagation();
            setSearchActive(true);
          }}
          onKeyUp={onKeyUp}/>
        <button type={'button'} onClick={onSubmitQuery}
                className={`hidden desktop:block w-[115px] bg-blue-primary text-white text-[16px] leading-[24px] border-0 rounded-2 h-full ${hoverBtnClass}`}>
          {Lang.getMessage('search')}
        </button>
        {
          (modals.includes('mobileSearch') || modals.includes('search')) &&
          isMobile && (
            <span
              className={'absolute right-0 top-0 h-full w-10 flex justify-center items-center'}
              onClick={() => {
                // сброс данных поиска и закрытие окна
                dispatchSearch({type: 'setQuery', payload: ''});
                setSearchActive(false);
              }}>
              <Icon name={'close-icon'} className="h-4 w-4"/>
            </span>
          )
        }
      </form>
      {children}
    </div>
  );
}

export default SearchForm;