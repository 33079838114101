import React, {useContext, useEffect} from 'react';
import Icon from './images/scrollTop.svg';

function ScrollTop(props) {
  const {sticky} = props;
  const [show, setShow] = React.useState(sticky);

  useEffect(() => {
    setShow(sticky);
  }, [sticky])

  return (
    <img src={Icon}
         onClick={() => {
           window.document.body.scrollIntoView({ block: "start", behavior: "smooth" });
         }}
         className={`${show
           ? 'fixed'
           : 'hidden'} object-contain bottom-[82px] left-[30px] cursor-pointer z-10 w-[56px] h-[57px] opacity-50 hover:opacity-100`}/>
  );
}

export default ScrollTop;