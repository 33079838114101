import React, {useContext} from 'react';
import Lang from '../Lang/Lang';
import {eventEmitter, hoverBtnClass, lang} from '../../common/baseData';
import {
  BasketContext,
  UserContext,
} from '../Context/ApplicationContextProvider';
import Icon from '../Icon/Icon';

function Product(props) {
  const {
    BASE_PRICE, BASE_PRICE_FORMATED, CORP_PRICE, CORP_PRICE_FORMATED, PROPERTY_CASHBACK_VALUE, last, itemKey,
    ID, IMAGE_379_SRC, IS_NEW, IS_TOP, SPECIAL_DEAL, IS_INCLUDED_IN_DEAL, URL,
    DISCOUNT_TOTAL_PERCENT, DISCOUNT_TOTAL_PERCENT_FORMATED, CASHBACK_FORMATED,
    PROPERTY_CML2_ARTICLE_VALUE, CREDIT_SERVICES, COLOR_OFFERS,
    OLD_PRICE, OLD_PRICE_FORMATED, PRICE, PRICE_FORMATED, rootClassName,
  } = props;

  const {isCorpClient} = useContext(UserContext);

  const basketItems = useContext(BasketContext);

  const inBasket = Array.isArray(basketItems) &&
    basketItems.find((element, index) => {
      return Number(element.productId) === Number(ID);
    }) !== undefined;

  const defaultRootClassName = `flex flex-col shrink-0 w-[171px] py-4 px-3.5 border ${!last
    ? 'border-r-0'
    : ''} border-solid border-grey-5 xl:basis-1/4 xl:max-w-[calc(25%-1px)] 2xl:basis-1/5 2xl:max-w-[calc(20%-1px)] desktop:px-10 desktop:py-6`;

  const title = props[`NAME_${lang.toUpperCase()}`];

  // определение финальной цены в зависимости от групп клиента
  const finalPrice = isCorpClient && CORP_PRICE && CORP_PRICE < PRICE ? CORP_PRICE : PRICE;
  const finalPriceFormated = isCorpClient && CORP_PRICE && CORP_PRICE < PRICE ? CORP_PRICE_FORMATED : PRICE_FORMATED;

  const basePrice = BASE_PRICE;
  const discount = basePrice - finalPrice;
  const discountPercent = discount > 0 ? Math.round((discount / basePrice) * 100) : 0;
  const discountPercentFormated = `- ${discountPercent} %`;

  const oldPriceDecoration = isCorpClient ? 'decoration-purple-corporate' : 'decoration-red-line-through'

  //console.info(title, basePrice, discount, discountPercent, finalPrice, finalPriceFormated);

  return (
    <li className={rootClassName ? rootClassName : defaultRootClassName}
        key={itemKey}>
      {/*<!-- картинка и лейблы -->*/}
      <div className="flex items-center justify-center relative">
        <div
          className="flex items-center justify-center desktop:w-[235px] desktop:h-[235px]">
          <img src={IMAGE_379_SRC}
               title={title}
               alt={title}
               width="230"
               height="230"
               loading="lazy"
               className="object-fill w-full h-auto"/>
        </div>
        <ul className="absolute top-0 left-0 flex flex-col gap-1.5">
          <li key={`${itemKey}-labels`}>
            {
              IS_NEW ?
                <span
                  className="bg-sky-primary px-2 rounded-2 text-white text-[12px] leading-[21px] inline-block">
                  {Lang.getMessage('new')}
                </span> :
                IS_INCLUDED_IN_DEAL && SPECIAL_DEAL &&
                SPECIAL_DEAL.hasOwnProperty('TITLE') ?
                  <span
                    className="bg-sky-primary px-2 rounded-2 text-white text-[12px] leading-[21px] inline-block">
                      {SPECIAL_DEAL.TITLE}
                  </span> :
                  IS_TOP ?
                    <span
                      className="bg-sky-primary px-2 rounded-2 text-white text-[12px] leading-[21px] inline-block">
                        {Lang.getMessage('topSales')}
                    </span> :
                    <></>

            }
          </li>
          {
            discountPercent > 0 && (
              <li key={`${itemKey}-discount`}>
                <span
                  className="bg-red-percent px-1.5 rounded-2 text-white text-[12px] leading-[22px] inline-block">
                    {discountPercentFormated}
                </span>
              </li>
            )
          }
        </ul>
        {/*<!-- акция -->*/
          SPECIAL_DEAL && SPECIAL_DEAL.hasOwnProperty('PICTURE') && (
            <img src={SPECIAL_DEAL.PICTURE.src}
                 className="object-cover absolute bottom-0 left-0 h-10 w-10 desktop:w-14 desktop:h-14"/>
          )
        }
      </div>
      {/*<!-- наименование и артикул -->*/}
      <div className="mt-4 flex flex-col">
        <span className="text-[11px] leading-[14px] h-[14px] text-grey-80">
            {PROPERTY_CML2_ARTICLE_VALUE}
        </span>
        <a href={URL}
           className="text-[14px] leading-[18px] text-grey-90 line-clamp-3 h-[54px]">
          {title}
        </a>
      </div>
      {/*<!-- банки и цвета -->*/}
      <div className="mt-4 grid grid-cols-2 items-center h-6">
        <ul className="flex gap-1" key={`banks-${itemKey}`}>
          {
            Array.isArray(CREDIT_SERVICES) && CREDIT_SERVICES.length > 0 && (
              CREDIT_SERVICES.map((service, index) => (
                <li className="w-6 h-6" key={`service-${itemKey}-${index}`}>
                  <img src={service.image_src}
                       className="object-fill"
                       width="24px"
                       height="24px"
                       loading="lazy"
                       title={service.title}
                       alt={`${service.name} - ${service.title}`}/>
                </li>
              ))
            )
          }
        </ul>
        <ul className="flex gap-1 justify-end" key={`offers-for-${itemKey}-${ID}`}>
          {
            COLOR_OFFERS && Array.isArray(COLOR_OFFERS) ?
              <>
                {
                  COLOR_OFFERS.length > 0 && (
                    COLOR_OFFERS.map((offer, index) => {
                      return index < 2 ?
                        <li key={`offer-${itemKey}-${index}`}
                            className={`rounded-full w-[18px] h-[18px] ${offer.CLASS_NAME}`}>
                          <a href={offer.URL}
                             className="w-full h-full inline-block"/>
                        </li> :
                        <></>;
                    })
                  )
                }
                {
                  COLOR_OFFERS.length > 2 && (
                    <span className="text-[12px] leading-[18px]"
                          key={`offer-${itemKey}-more`}>
                      {`+${COLOR_OFFERS.length - 2}`}
                  </span>
                  )
                }
              </> :
              <></>
          }
        </ul>
      </div>
      {/*<!-- стоимость и кнопка купить -->*/}
      <div className="mt-4 flex justify-between items-end h-11">
        <div className="flex flex-col">
          {
              Number(BASE_PRICE) > 0 && Number(BASE_PRICE) > finalPrice && (
                <span className={`text-[12px] leading-[15px] h-[15px] line-through text-grey-50 ${oldPriceDecoration} desktop:text-[14px] desktop:leading-[17px] desktop:h-[17px]`}>
                    {BASE_PRICE_FORMATED}
                </span>
            )
          }
          <span className="text-[16px] leading-[20px] h-5 font-semibold text-grey-90 desktop:text-[20px] desktop:leading-[24px] desktop:h-6">
              {finalPriceFormated}
          </span>
        </div>
        <button type="button"
                className={`flex items-center justify-center bg-blue-primary p-0 border-0 w-11 h-[34px] desktop:w-14 desktop:h-11 ${hoverBtnClass}`}>
          {
            inBasket ?
              <Icon name={'in-cart-icon'}
                    className="h-[22px] w-[29px]"
                    onClick={event => {
                      eventEmitter.emit('openSmallCart');
                    }}/> :
              <Icon name={'add-to-cart-icon'}
                    className="h-[33px] w-[33px]"
                    onClick={event => {
                      eventEmitter.emit('addToCart', {productId: Number(ID)});
                    }}/>
          }
        </button>
      </div>
      {/*<!-- кешбек -->*/
        PROPERTY_CASHBACK_VALUE && PROPERTY_CASHBACK_VALUE > 0 && (
          <span className="mt-2 text-grey-90 text-[12px] leading-[15px] h-[15px]">
            {`${Lang.getMessage('cashback')} ${CASHBACK_FORMATED}`}
          </span>
        )
      }
    </li>
  );
}

export default Product;