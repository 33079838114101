import React, {useContext, useEffect, useRef, useState} from 'react';

function FirstLine(props) {
  const {children} = props;

  return (
    <div className="flex justify-between items-center w-full max-w-[1600px] mt-4 px-5 h-10 desktop:h-[43px]">
      {children}
    </div>
  );
}

export default FirstLine;