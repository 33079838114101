import React, {useState, useEffect, useRef, useContext} from 'react';
import Navigation from '../Navigation/Navigation';
import {
  ClientWidthContext,
  ModalContext,
  ModalDispatchContext,
} from '../Context/ApplicationContextProvider';
import {eventEmitter} from '../../common/baseData';

function DropdownNavigation(props) {
  const modals = useContext(ModalContext);
  const dispatchModal = useContext(ModalDispatchContext);
  const clientWidth = useContext(ClientWidthContext);

  const {sticky, page} = {...props};
  const [hoveredRootSectionId, setHoverOnRootSectionId] = React.useState(null);
  const [sectionTreeHeight, setSectionTreeHeight] = React.useState(null);

  const [sectionTreeData, setSectionTreeData] = useState(window.sectionTree || {
    rootSections: null,
    sections: null,
    popularSections: null,
    popularLinks: null,
  });

  const sectionCheckedClass = Number(hoveredRootSectionId) ? 'w-full' : 'w-fit';
  const sectionClass = sticky ? 'top-[-16px] left-0' : 'top-0 left-[-20px]';
  const wrapperClass = sticky || clientWidth > 1600 ? 'w-full' : 'w-[1600px]';

  const [showNavigation, setShowNavigation] = React.useState(
    modals.includes('navigation'));

  useEffect(() => {
    setShowNavigation(modals.includes('navigation'));
  }, [modals]);

  useEffect(() => {
    eventEmitter.on('closeNavigation', dropCheckedSection);

    return () => {
      eventEmitter.off('closeNavigation', dropCheckedSection);
    }
  }, []);

  function dropCheckedSection() {
    setHoverOnRootSectionId(null);
  }

  return (
    <div className={`${showNavigation ? '' : 'hidden'} ease-linear transition-display duration-700 relative self-end ${wrapperClass} max-w-[1600px] m-auto`}
         onMouseLeave={() => {
           dispatchModal({type: 'hide', modalType: 'navigation'});
         }}>
      <section id="navigation-root"
               className={`absolute ${sectionCheckedClass} p-5 pt-6 ${sectionClass} right-0 flex gap-12 z-30 bg-white`}>
        <Navigation setHeight={setSectionTreeHeight}
                     {...sectionTreeData}
                     hoveredRootSectionId={hoveredRootSectionId}
                     sectionTreeHeight={sectionTreeHeight}
                     setHoverOnRootSectionId={setHoverOnRootSectionId}/>
      </section>
    </div>
  );
}

export default DropdownNavigation;