import './TapBar.css';
import React, {useContext, useEffect, useState} from 'react';
import MenuIcon from './images/menu.svg';
import CatalogIcon from './images/mob-catalog.svg';
import BasketIcon from './images/basket.svg';
import ConnectIcon from './images/connect.svg';
import PersonalIcon from './images/personal.svg';
import MobileMenu from './mobileMenu/MobileMenu';
import MobileCatalog from './mobileCatalog/MobileCatalog';
import MobileNav from './mobileNav/MobileNav';
import MobilePersonal from './mobilePersonal/MobilePersonal';
import MobileCitySwitch from './mobileCitySwitch/MobileCitySwitch';
import {eventEmitter, lang} from '../../common/baseData';
import {
  BasketContext,
  ClientWidthContext,
} from '../Context/ApplicationContextProvider';

function TapBar(props) {
  const basket = useContext(BasketContext);
  const clientWidth = useContext(ClientWidthContext);

  const [state, setState] = useState({
    menu: [],
    catalog: [],
    popLink: [],
    popSec: [],
    personal: [],
    siteSwitch: [],
    citySwitch: {},
    messages: {},
    isAuth: false,
  });

  const [show, setShow] = useState(clientWidth < 1280);
  const [showCitySwitch, setShowCitySwitch] = useState(false);
  const [popBlock, setPopBlock] = useState(null);

  const menuTypes = [
    {
      icon: MenuIcon,
      type: 'menu',
    },
    {
      icon: CatalogIcon,
      type: 'catalog',
    },
    {
      icon: BasketIcon,
      type: 'basket',
    },
    {
      icon: PersonalIcon,
      type: 'personal',
    },
    {
      icon: ConnectIcon,
      type: 'connect',
    },
  ];
  const [activeMenuType, setActiveMenuType] = useState(null);
  const [menuOpened, setMenuOpened] = useState(false);
  const [activeCatalogSection, setActiveCatalogSection] = useState(null);

  // init base state
  useEffect(() => {
    try {
      let baseData = JSON.parse(window.mobileMenuInitialState);
      setState({
        menu: baseData.menu,
        catalog: baseData.catalog,
        popLink: baseData.popLink,
        popSec: baseData.popSec,
        personal: baseData.personal,
        siteSwitch: baseData.siteSwitch,
        citySwitch: baseData.citySwitch,
        messages: baseData.messages,
        isAuth: baseData.isAuth,
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    initEvents();
  }, []);

  /**
   * Подготовка списка популярных ссылок
   */
  useEffect(() => {
    if (activeCatalogSection !== null &&
      activeCatalogSection.hasOwnProperty('parentId')) {
      let pop_block = state.popSec.find(
        item => Number(item.parent_id) === Number(activeCatalogSection.itemId));
      if (pop_block) pop_block.links = state.popLink.filter(
        item => pop_block.link_id.includes(item.rec_id)).sort((a, b) => {
        if (isNaN(a.sort) || isNaN(b.sort) || Number(a.sort) ===
          Number(b.sort)) return 0;
        return Number(a.sort) > Number(b.sort) ? 1 : -1;
      });
      setPopBlock(pop_block);
    } else setPopBlock(null);
  }, [activeCatalogSection]);

  useEffect(() => {
    setShow(clientWidth < 1280);
  }, [clientWidth]);

  // prevent page scroll
  useEffect(() => {
    if (menuOpened) document.body.classList.add('stop-scrolling');
    else document.body.classList.remove('stop-scrolling');
  }, [menuOpened]);

  // drop catalog section state on change menu type
  useEffect(() => {
    setActiveCatalogSection(null);
  }, [activeMenuType]);

  function initEvents() {
    eventEmitter.on('showMobileCatalog', () => {
      showMenuDirection('catalog');
    });
  }

  function closeMenu() {
    setActiveMenuType(null);
    setMenuOpened(false);
    setShowCitySwitch(false);
  }

  /**
   * Handles the back button click event in a catalog navigation interface.
   * If the active catalog section is not null:
   *   - If the depth level of the active section is 1, it resets the active section to null.
   *   - Otherwise, it sets the active section to its parent section.
   * If the active catalog section is null, it redirects to the main menu.
   *
   * @return {void}
   */
  function onBackBtnClick() {
    if (activeCatalogSection !== null) {
      if (Number(activeCatalogSection.depthLevel) === 1) {
        setActiveCatalogSection(null);
      } else { // поиск родителя
        setActiveCatalogSection(
          findParentSection(Number(activeCatalogSection.itemId)));
      }
    } else {
      showMenuDirection('menu');
    }
  }

  /**
   * Finds and returns the parent section from the catalog by a given parent ID.
   *
   * @param {number} parentId - The ID of the parent section to find.
   * @return {Object|null} - The parent section object if found, otherwise null.
   */
  function findParentSection(parentId) {
    for (let item of state.catalog) {
      if (item.hasOwnProperty('itemId') && (item.itemId) > 0 &&
        Number(item.itemId) === Number(activeCatalogSection.parentId)) {
        return item;
        break;
      }
    }
    return null;
  }

  function showMenuDirection(type) {
    switch (type) {
      case 'menu':
        setMenuOpened(true);
        setActiveMenuType('menu');
        break;
      case 'catalog':
        setMenuOpened(true);
        setActiveMenuType('catalog');
        break;
      case 'connect':
        // open social widget
        initOpenSocialWidget();
        break;
      case 'personal':
        // Check user auth
        if (!state.isAuth) eventEmitter.emit('showAuth');
        else if (state.personal.length) {
          setMenuOpened(true);
          setActiveMenuType('personal');
        }
        break;
      case 'basket':
        // Check basket items
        initOpenBasket();
        break;
    }
  }

  function onShowCitySwitch(event) {
    event.preventDefault();
    setActiveMenuType(null);
    setShowCitySwitch(true);
  }

  function initOpenBasket() {
    if (Array.isArray(basket) && basket.length) {
      if (menuOpened) setMenuOpened(false);
      if (activeMenuType !== null) setActiveMenuType(null);

      eventEmitter.once('basketInited', () => {
        window.sessionStorage.setItem('basketRequested', 'true');
      });
      eventEmitter.emit('openSmallCart');
    }
  }

  function initOpenSocialWidget() {
    // const socialWidget = new SocialWidget()
    let socialNetworksHolder = document.querySelector(
      '.follow-us-social-buttons-holder');
    if (socialNetworksHolder instanceof HTMLElement) {
      socialNetworksHolder.classList.toggle('active');
    }
  }

  function getBlockTitle(linkId) {
    return state.popLink.find(pop_link => linkId == pop_link.rec_id);
  }

  const menuItems = menuTypes.map((item, key) => {
    return <li key={key}
               className={'w-full h-full flex flex-col justify-center items-center p-0 cursor-pointer'}
               onClick={event => showMenuDirection(item.type)}>
      <div className={'relative'}>
        <img src={item.icon} alt={item.title} title={item.title} width={'19px'}
             height={'19px'}/>
        {
          item.type === 'basket' && Array.isArray(basket) &&
          basket.length > 0 && (
            <span className={'tap-bar-item-count'}>{basket.length}</span>
          )
        }
      </div>
      <span
        className={'text-[10px] leading-[13px] text-grey-90'}>
        {state.messages.hasOwnProperty(item.type)
          ? state.messages[item.type]
          : 'Title not defined'}
      </span>
    </li>;
  });

  const menuTitle = activeMenuType !== null &&
  state.messages.hasOwnProperty(activeMenuType) ?
    state.messages[activeMenuType] :
    showCitySwitch && state.citySwitch.hasOwnProperty('messages') &&
    state.citySwitch.messages.hasOwnProperty('CITY_SWITCH_CHOICE_CITY') ?
      state.citySwitch.messages['CITY_SWITCH_CHOICE_CITY'] :
      activeMenuType;

  return (
    show ?
      <>
        <MobileMenu opened={menuOpened}
                    onClose={closeMenu}
                    onBackBtnClick={onBackBtnClick}
                    activeMenuType={activeMenuType}
                    title={menuTitle}
                    section={activeCatalogSection}
                    sectionBlockTitle={popBlock &&
                    Number(popBlock.title_link_id) ? getBlockTitle(
                      popBlock.title_link_id) : null}
                    siteSwitch={state.siteSwitch}
                    citySwitch={state.citySwitch}
                    onShowCitySwitch={onShowCitySwitch}>
          {
            activeMenuType === 'catalog' ?
              <MobileCatalog items={state.catalog}
                             popBlock={popBlock}
                             findParentSection={findParentSection}
                             section={activeCatalogSection}
                             onBackBtnClick={onBackBtnClick}
                             setActiveCatalogSection={setActiveCatalogSection}
                             activeSectionId={activeCatalogSection !== null
                               ? Number(activeCatalogSection.itemId)
                               : 0}/> :
              <></>
          }
          {
            activeMenuType === 'menu' ?
              <MobileNav items={state.menu}
                         openCatalog={() => {showMenuDirection('catalog');}}
                         catalogTitle={state.messages.hasOwnProperty('catalog')
                           ? state.messages['catalog-mob']
                           : 'catalog'}/> :
              <></>
          }
          {
            activeMenuType === 'personal' && state.isAuth &&
            state.personal.length ?
              <MobilePersonal items={state.personal}
                              initOpenBasket={initOpenBasket}/> :
              <></>
          }
          {
            showCitySwitch ?
              <MobileCitySwitch {...state.citySwitch}/> :
              <></>
          }
        </MobileMenu>
        <ul
          className={'fixed list-none bottom-0 w-full flex items-center justify-around p-0 gap-2 bg-white isolate h-[66px] z-[3] pb-[10px] border-solid border-0 border-t border-blue-primary 400:pr-[10px]'}>
          {menuItems}
        </ul>
      </> :
      <></>
  );
}

export default TapBar;