import './style.css'

import $ from 'jquery'
//import 'bootstrap'
//import '@babel/polyfill'

import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom'
//import HeaderSearch from '../header/search'

//import autocomplete from 'autocompleter'
//import 'slick-wh/slick/slick.css'
//import { HeaderCallback } from '../header/phones'
//import { LangSwitch } from '../header/languages-switch'
import { SeoText } from '../header/seo-text'
import { AuthRegister } from '../header/auth-register'
import { HeaderBanner } from '../header/banner'
import 'lazysizes/plugins/respimg/ls.respimg.min.js'
import 'lazysizes/plugins/bgset/ls.bgset'
import 'lazysizes'
//import { ProductWishlist } from '../productWishlist'
//import { ProductCompare } from '../productCompare'
import { InfoBanner } from '../infoBanner'
import { CommercialBanner } from '../commercialBanner'
import PreLoader from '../preLoader'

const CreditBasketModal = lazy(() => import('../components/creditBasketModal/CreditBasketModal'))
/*import Basket from '../components/basket/Basket'
import TapBar from '../components/tapBar/TapBar'
import CatalogDropdownMenu from '../components/catalogDropdownMenu/CatalogDropdownMenu'*/
//import { HeaderCommon } from '../header/common'
import ReportAvailability from '../reportAvailability';
import {createRoot} from 'react-dom/client';
import {
  productWishlist, productCompare,
  eventEmitter,
  facebook,
  analytics,
  sessid,
  lang,
  isAuth,
  userData,
  fuserId,
  currentCityId,
  currentCityName,
  userId,
  userEsputnikId,
  userName,
  userEmail,
  userPhone,
  dynRem,
  esputnikTracker,
  axiosConfig,
  multisearchTracking,
  userCityData,
  axiosInstance,
  price,
  compareItems
} from './baseData'

// Init async image loading
lazySizes.init()


if (window.sessionStorage.hasOwnProperty('select_item_category')) {
  let category_select_item_id = window.sessionStorage.getItem('select_item_category')
  if (!isNaN(Number(category_select_item_id))) {
    let item_id = Number(category_select_item_id)
    analytics.selectItem(item_id, 'Category Products', 'category_products')
      .then(response => {
        console.info(response, item_id)
      })
      .catch(reason => {
        console.error(reason)
      })
    window.sessionStorage.removeItem('select_item_category')
  }
}

(new PreLoader({ eventEmitter: eventEmitter })).initLoader()

// Init catalog dropdown menu
/*let catalogDropdownMenuNode = document.getElementById('catalog-dropdown-menu-root')
if (catalogDropdownMenuNode instanceof HTMLElement) {
  try {
    let menuData = JSON.parse(window.catalogMenuData)
    const dropdownRoot = createRoot(catalogDropdownMenuNode)
    dropdownRoot.render(<CatalogDropdownMenu {...menuData}/>)
  } catch (reason) {
    console.error(reason)
  }
}*/

// Init tap bar
/*let tapBarNode = document.getElementById('tap-bar-root')
if (tapBarNode instanceof HTMLElement) {
  let desktopLogo = tapBarNode.getAttribute('data-params')
  let menuData = {}

  const tapBarRoot = createRoot(tapBarNode)
  tapBarRoot.render(<Suspense fallback={<></>}><TapBar desktopLogo={desktopLogo} {...menuData}/></Suspense>)
}*/

// Кредитное окно корзины
let creditBasketNode = document.getElementById('credit-basket-root')
if (creditBasketNode instanceof HTMLElement) {
  let creditbasketParams = {
    template: creditBasketNode.dataset.template,
    params: creditBasketNode.dataset.params,
    component: creditBasketNode.dataset.component,
  }

  creditBasketNode.dataset.template = ''
  creditBasketNode.dataset.params = ''
  creditBasketNode.dataset.component = ''

  const creditRoot = createRoot(creditBasketNode)
  creditRoot.render(<Suspense fallback={<></>}><CreditBasketModal {...creditbasketParams}/></Suspense>)
}

// Корзина
/*let basketNode = document.getElementById('bid')

if ((basketNode instanceof HTMLElement)) {
  let initialState = JSON.parse(atob(window.basket_initial))
  //basketNode.dataset.params = ''

  // Загрузка модуля корзины
  let page = '/ua/personal/cart/'.localeCompare(window.location.pathname) === 0 || '/personal/cart/'.localeCompare(window.location.pathname) === 0 ? 'checkout' : 'site'
  // Render корзины
  const basketRoot = createRoot(document.querySelector('#basket-root'))
  basketRoot.render(<Basket basketNode={basketNode} initialState={initialState} page={page}/>)
}*/

//Header banner
let bannerNode = document.querySelector('[data-container="header-banner"]')
if (bannerNode instanceof HTMLElement) {
  let bannerParams = bannerNode.dataset.hasOwnProperty('params')
    ? JSON.parse(atob(bannerNode.dataset.params)) : {}
  let headerBanner = new HeaderBanner(bannerNode, bannerParams)
  headerBanner.render().adaptParentsNode().bindEvents()
}

// User bonus
/*let bonusNodeList = [].slice.apply(document.querySelectorAll('[data-type="bonus"]'))
if (bonusNodeList.length > 0) {
  fetch('/local/user.bonus.ajax.php').then(response => {
    return response.json()
  }).then(data => {
    if (data.hasOwnProperty('value')) {
      bonusNodeList.forEach(item => {
        item.innerHTML = data.value
        let bonusSumTooltip = document.querySelector('.user-bonus-value')
        item.style.display = 'unset'
        bonusSumTooltip.innerHTML = `
                         <span class="bonus-sum">${data.value}</span>
                         <span class="tooltip-text">
                            <span class="tooltip-text-holder">
                                <span class="tooltip-box">
                                    <span>${authRegister.getMessage('BONUS_SUM_TOOLTIP')}</span>
                                </span>
                            </span>
                        </span>`
      })
      let cashbSum = document.querySelector('.bonus-sum')
      if (cashbSum instanceof HTMLElement) {
        if (data.value > 9999) {
          cashbSum.classList.add('big-sum')
        }
      }
    }
  })
}*/

//Search Title
/*let search_node = document.getElementById('bx-title-search')
let search_node_mob = document.getElementById('mobile-search')
if (search_node instanceof HTMLElement) {
  if (window.innerWidth < 1024) {
    if (search_node_mob instanceof HTMLElement) {
      search_node_mob.appendChild(search_node)
    }
  }
  let raw_params = atob(search_node.getAttribute('data-params'))
  let params = JSON.parse(raw_params)
  params.node = search_node
  search_node.setAttribute('data-params', '')

  const headerSearchRoot = createRoot(search_node)
  headerSearchRoot.render(<HeaderSearch params={params}/>)
}*/

// Check info banner exist
let infoBannerElement = document.querySelector('infobanner')
if (infoBannerElement instanceof HTMLElement && infoBannerElement.dataset.hasOwnProperty('src') && infoBannerElement.dataset['src'].length) {
  let infoBanner = new InfoBanner()
  infoBanner.body = `<img src="${infoBannerElement.dataset['src']}">`
  infoBanner.open()
}

// Check commercial banner exist
let commercialBannerElement = document.querySelector('commercialbanner')
if (commercialBannerElement instanceof HTMLElement && commercialBannerElement.dataset.hasOwnProperty('src') && commercialBannerElement.dataset['src'].length) {
  let commercialBanner = new CommercialBanner()
  commercialBanner.body = `
    <a href="${commercialBannerElement.dataset['link']}">
        <img src="${commercialBannerElement.dataset['src']}">
    </a>
  `
  commercialBanner.open()
}

// After add to basket event handler
eventEmitter.on('addedToCart', data => {
  // Switch svg icon on mini buttons
  [].slice.apply(document.querySelectorAll(`[data-container="buy"][data-use="order"][data-product-id="${data.productId}"]`))
    .forEach(el => {
      el.classList.add('in-basket')
      if (el.dataset.hasOwnProperty('inBasket') && el.dataset['inBasket'].length > 0) {
        el.style.background = el.dataset['inBasket']
      }
      if (el instanceof HTMLElement) {
        let inIcon = el.querySelector('[data-show="in-basket"]')
        let addIcon = el.querySelector('[data-show="basket"]')
        if (inIcon && addIcon) {
          inIcon.style.display = ''
          addIcon.style.display = 'none'
        }
      }
    })
})

// Switch svg icon on mini buttons
eventEmitter.on('removedCartItem', data => {
  let el = document.querySelector(`.buy-btn_mini[data-product-id="${data.productId}"]`)
  if (el instanceof HTMLElement) {
    el.classList.remove('in-basket')
    // el.style.background = 'unset';
    let inIcon = el.querySelector('[data-show="in-basket"]')
    let addIcon = el.querySelector('[data-show="basket"]')
    if (inIcon && addIcon) {
      inIcon.style.display = 'none'
      addIcon.style.display = ''
    }
  }
})

// Switch svg icon on mini buttons for search
eventEmitter.on('removedCartItem', data => {
  let elBuy = document.querySelector(`.buy_btn[data-product-id="${data.productId}"]`)
  if (elBuy instanceof HTMLElement) {
    elBuy.classList.remove('in-basket')
    // elBuy.style.background = 'unset';
    let inIcon = elBuy.querySelector('[data-show="in-basket"]')
    let addIcon = elBuy.querySelector('[data-show="basket"]')
    if (inIcon && addIcon) {
      inIcon.style.display = 'none'
      addIcon.style.display = ''
    }
  }
})

/*let productWishlist = new ProductWishlist({
  eventEmitter: eventEmitter,
  isAuth: isAuth,
  fuserId: fuserId,
  sessid: sessid
})

// Wishlist after item add event - update counters
eventEmitter.on('refreshWishList', (data) => {
  let headerPanel = document.querySelector('header')
  // Load updated items list
  productWishlist.loadItems()
    .then(jsonString => {
      return jsonString.json()
    }).then(response => {
    let countItems = [].slice.apply(response.items).length;
    [].slice.apply(document.querySelectorAll('[data-container="favorite-counter"]'))
      .forEach(item => {
        let displayValue = window.getComputedStyle(item).getPropertyValue('display')
        let favoriteBlockNode = item.closest('.favorite')
        if (countItems > 0) {
          item.textContent = countItems
          if (displayValue === 'none' && item.style.display !== 'none') item.style.display = 'block'
          else if (item.style.display === 'none') item.style.display = ''

          if (favoriteBlockNode instanceof HTMLElement)
            favoriteBlockNode.classList.add('show')

          if (favoriteBlockNode.classList.contains('show'))
            item.style.display = 'block'

          if (headerPanel instanceof HTMLElement)
            headerPanel.classList.add('in-favorites')

        } else {
          item.style.display = 'none'

          if (favoriteBlockNode instanceof HTMLElement)
            favoriteBlockNode.classList.remove('show')

          if (headerPanel instanceof HTMLElement)
            headerPanel.classList.remove('in-favorites')
        }
      })
  })
})

let productCompare = new ProductCompare({
  items: compareItems ? someData.compareItems : []
})

// Compare after item add event - update counters
eventEmitter.on('refreshCompareList', (countItems) => {
  let headerPanel = document.querySelector('header');
  // Load updated items list
  [].slice.apply(document.querySelectorAll('[data-container="compare-counter"]'))
    .forEach(item => {
      let displayValue = window.getComputedStyle(item).getPropertyValue('display')
      let comparingBlockNode = item.closest('.comparing')
      if (countItems > 0) {
        item.textContent = countItems
        if (displayValue === 'none' && item.style.display !== 'none') item.style.display = 'block'
        else if (item.style.display === 'none') item.style.display = ''

        if (comparingBlockNode instanceof HTMLElement)
          comparingBlockNode.classList.add('show')

        if (headerPanel instanceof HTMLElement)
          headerPanel.classList.add('compared')
      } else {
        item.style.display = 'none'

        if (comparingBlockNode instanceof HTMLElement)
          comparingBlockNode.classList.remove('show')

        if (headerPanel instanceof HTMLElement)
          headerPanel.classList.remove('compared')
      }
    })
})

eventEmitter.on('updateCompareListState', (compareButtonsList) => {
  // Load updated items list
  if (compareButtonsList instanceof Array && compareButtonsList.length) {
    compareButtonsList.forEach(button => {
      if (!button.parentNode.classList.contains('active')) {
        button.parentNode.classList.add('active')
      }
    })
  }
})*/

// Whole site events
document.addEventListener('click', event => {
  let el = event.target
  if (el.classList.contains('bg') && el.classList.contains('active')) {
    eventEmitter.emit('popupBackgroungClick', event)
  }
})

/*let langSwitch = new LangSwitch('bx-header-site-switch')*/
//HeaderCommon.init()
$(document).ready(function () {
  setTimeout(() => {
    let imgItems = [].slice.apply(document.querySelectorAll('img.lateload'))
    if (imgItems.length > 0) {
      imgItems.forEach(item => {
        if ((item instanceof HTMLElement) && item.dataset.hasOwnProperty('src')) {
          item.setAttribute('src', item.dataset.src)
          item.classList.remove('lateload')
        }
      })
    }
  }, 4000)

  setTimeout(() => {
    let imgItems = [].slice.apply(document.querySelectorAll('source.lateload'))
    if (imgItems.length > 0) {
      imgItems.forEach(item => {
        if ((item instanceof HTMLElement) && item.dataset.hasOwnProperty('srcset')) {
          item.setAttribute('srcset', item.dataset.srcset)
          item.classList.remove('lateload')
        }
      })
    }
  }, 4000)

  if (document.getElementsByClassName('h_customScrollbar').length > 0) {
    import(/* webpackChunkName: 'contentmenu' */ '../header/content-menu').then(module => {
      module.ContentMenu.init()
    })
  }
})

// Site Seo Text
let seoBlocks = document.querySelectorAll('.opening-text')
if (seoBlocks.length) {
  seoBlocks.forEach((item) => {
    let textBlock = item.parentNode.querySelector('.text-block')

    if (!(textBlock instanceof HTMLElement))
      textBlock = item.parentNode.querySelector('.text-content')

    if (!(textBlock instanceof HTMLElement)) {
      item.addEventListener('click', (e) => {
        e.preventDefault()
        SeoText.toggleState(item.parentElement)
      })
      return
    }

    if (textBlock.scrollHeight > 240 || textBlock.classList.contains('text-content')) {
      item.addEventListener('click', (e) => {
        e.preventDefault()
        SeoText.toggleState(item.parentElement)
      })
    } else {
      item.style.display = 'none'
      textBlock.parentNode.classList.add('opened')
    }
  })
}

//Catalog section description
let descBlockList = document.querySelectorAll('.cat-section-desc')
if (descBlockList.length) {
  descBlockList.forEach(node => {
    let textNode = node.querySelector('[data-container="description-text"]')
    let buttonNode = node.querySelector('[data-container="button"]')
    let bannerNode = node.querySelector('.image-block')
    if (!(textNode instanceof HTMLElement) || !(buttonNode instanceof HTMLElement))
      return

    buttonNode.addEventListener('click', e => {
      e.preventDefault()
      openDescriptionText()
    })

    bannerNode.addEventListener('click', e => {
      e.preventDefault()
      openDescriptionText()
    })

    function openDescriptionText () {
      textNode.classList.toggle('opened')
      if (textNode.classList.contains('opened')) {
        const elementPosition = textNode.getBoundingClientRect().top
        window.scrollBy({
          top: elementPosition - 50,
          behavior: 'smooth'
        })
      }
    }

  })
}

/*let headerCallbackBlockNode = document.querySelector('[data-container="header-callback"]')
if (headerCallbackBlockNode instanceof HTMLElement)
  new HeaderCallback(headerCallbackBlockNode).render().initEvents()*/

// Авторизация (новая)
/*let authNode = document.getElementById('auth-root')
if ((authNode instanceof HTMLElement) && !isAuth) {
  // Загрузка модуля
  const Authorization = lazy(() => import('../components/authorization/Authozation'))
  // Render
  const authRoot = createRoot(authNode)
  authRoot.render(<Suspense fallback={<></>}><Authorization authNode={authNode} lang={lang}/></Suspense>)
}*/

// TODO найти лучшую реализацию
/*$(document).on('hidden.bs.modal', '#modal-basket', function () {
  $('.lt-label-mobile').show()
})*/


let timerId;

function startTimer(sec, authRegister) {
  timerId = setInterval(function() {
    if(authRegister.showTimer(sec)) {
      sec--;
      authRegister.showTimer(sec)
      if (sec < 0) {
        clearInterval(timerId);
        authRegister.showTextContent()
      }

      authRegister.sendingRequest = false
    }
  }, 1000);
}

let userBlock = document.querySelector('.user-login .user-link')
if (userBlock instanceof HTMLElement) {
  let needAuth = !!(userBlock.dataset['needAuth'] === 'true')
  let params = {
    messages: JSON.parse(atob(userBlock.dataset.messages)),
    url: atob(userBlock.dataset.loadUrl),
    menuPath: atob(userBlock.dataset.menuUrl)
  }
  params.sessid = sessid
  params.lang = lang
  params.eventEmitter = eventEmitter
  if (needAuth) {
    let authRegister = new AuthRegister(params)
    authRegister.setCurrentMode('auth').setCurrentStep('searchUser').initTemplate().initStep()
    // Focus out input event
    let wrapper = authRegister.getOuter()
    wrapper.addEventListener('keyup', function (e) {
      if (e.target instanceof HTMLInputElement && !e.target.classList.contains('auth_secret_code'))
        authRegister.validateInput(e.target)
    })
    // Change input event
    wrapper.addEventListener('change', function (e) {
      if (e.target instanceof HTMLInputElement && !e.target.classList.contains('auth_secret_code')) authRegister.checkInput(e.target)
    })

    wrapper.addEventListener('submit', function (e) {
      e.preventDefault()
      if (authRegister.sendingRequest) return
      authRegister.sendingRequest = true
      let obj = authRegister.collectUserStepInput() // Get current step user input value/s
      if (obj.valid) {
        switch (authRegister.getCurrentStep()) {
          case 'searchUser':
            authRegister.searchUser(obj.data)
            clearInterval(timerId)
            startTimer(15 , authRegister);
            break
          case 'authUser':
            authRegister.setAuthValue(obj.data).authUser()
            break
          case 'fillRegisterData':
            authRegister.registerUser(obj.data)
            break
          case 'fillForgotData':
            authRegister.resetPassword(obj.data)
            break
        }
      }
      authRegister.sendingRequest = false
    })

    wrapper.addEventListener('click', function (e) {
      if (e.target.dataset.hasOwnProperty('action') && e.target.dataset['action'] === 'switchMode') {
        authRegister.switchMode(e.target.dataset['mode'])
        clearInterval(timerId)
        authRegister.showTextContent()
      }
      if (e.target.classList.contains('close-modal')) {
        e.preventDefault()
        authRegister.hide()
        clearInterval(timerId)
        authRegister.showTextContent()
      }
      if (e.target.classList.contains('change_param')) {
        clearInterval(timerId)
        authRegister.showTextContent()
        authRegister.setCurrentStep('searchUser').initStep()
      }

      if (e.target.classList.contains('resend_code') && !this.sendingRequest) {
        authRegister.sendingRequest = true
        authRegister.resendSmsCode().then(
          response => {
            try {
              response = JSON.parse(response)
              // Show user message
              if ('errors' in response && response.errors.length) {
                for (var i in response.errors) {
                  if (!response.errors.hasOwnProperty(i)) continue

                  if (response.errors[i].type === 'number_of_requests_exceeded') authRegister.showRequestError(response.errors)
                }
              }

              authRegister.hasResendSms = true
              if(authRegister.sendingRequest) {
                clearInterval(timerId)
                startTimer(16 , authRegister);
              }
            } catch (e) {
              authRegister.sendingRequest = false
              throw new Error(e.message)
            }
          },
          () => {
            authRegister.showRequestError()
            authRegister.sendingRequest = false
          }
        )
      }

      if (e.target.classList.contains('auth_password_forgot')) authRegister.sendResetPasswordData()
      if (e.target.classList.contains('modal')) {
        authRegister.hide()
        clearInterval(timerId)
        authRegister.showTextContent()
      }
      // Show / hide password
      if (e.target.classList.contains('pass_icon') || e.target.closest('.pass_icon')) {
        authRegister.switchPasswdFieldType(e)
      }
    })

    wrapper.addEventListener('input', function (e) {
      if (e.target.classList.contains('auth_secret_code')) {
        if (authRegister.sendingRequest || e.target.value.length !== 4) return false
        if (authRegister.checkFullPinCode(authRegister.getOuter())) {
          authRegister.sendingRequest = true
          let obj = authRegister.collectUserStepInput() // Get current step user input value/s
          authRegister.setAuthValue(obj.data).authUser()
        }
      }
    })

    // Init user auth event
    document.addEventListener('click', event => {
      let el = event.target
      if (el.dataset.hasOwnProperty('container') && el.dataset.hasOwnProperty('use')
        && el.dataset.container === 'user' && el.dataset.use === 'auth') {
        event.preventDefault()
        authRegister.show()
        if (window.innerWidth < 1024) {
          authRegister.closeAuthRegist()
        }

        //Link to wishlist in header
        if (el.dataset.hasOwnProperty('mode') && el.dataset.mode === 'favoriteLink')
          authRegister.showSpecialMessage(authRegister.getMessage('AUTH_ADD_FAVORITES'))
      }
    })

    eventEmitter.on('showAuth', mode => {
      authRegister.show()
      if (mode === 'favorite')
        authRegister.showSpecialMessage(authRegister.getMessage('AUTH_ADD_FAVORITES'))
    })

    eventEmitter.on('showRegister', mode => {
      authRegister.setCurrentMode('register').setCurrentStep('fillRegisterData').initStep().showRegiter()
    })

  } else {
    var authRegister = new AuthRegister(params)
    let userLoginBlockNode = userBlock.parentNode
    let curUrl = userBlock.dataset.hasOwnProperty('currentUrl') ? atob(userBlock.dataset.currentUrl) : ''

    userLoginBlockNode.addEventListener('mouseover', () => {
      userLoginBlockNode.classList.add('opened')
      if (!authRegister.personalMenuLoaded) {
        authRegister.personalMenuLoaded = true
        authRegister.loadPersonalMenu(curUrl).then(
          response => {
            userLoginBlockNode.insertAdjacentHTML('beforeend', response)
            // User bonus
            let bonusNode = userLoginBlockNode.querySelector('[data-type="bonus"]')
            if (bonusNode instanceof HTMLElement) {
              fetch('/local/user.bonus.ajax.php').then(response => {
                return response.json()
              }).then(data => {
                if (data.hasOwnProperty('value')) {
                  bonusNode.innerText = data.value
                  bonusNode.style.display = 'unset'
                }
              })
            }
          }
        )
      }
    })

    userLoginBlockNode.addEventListener('mouseout', () => {
      userLoginBlockNode.classList.remove('opened')
    })
  }

  // Register buttons processing
  let regButtons = [].slice.apply(document.querySelectorAll('a[data-container="user"][data-use="register"]'))
  regButtons.forEach(regButton => {
    if (needAuth) {
      regButton.addEventListener('click', e => {
        e.preventDefault()
        eventEmitter.emit('showRegister')
      })
    } else {
      let regButtonNew = document.createElement('div')
      regButtonNew.textContent = authRegister.getMessage('AUTH_OK')
      regButton.replaceWith(regButtonNew)
    }
  })
}

// Init global report availability/pre order event
document.addEventListener('click', e => {
  let el = e.target
  if (el.dataset.hasOwnProperty('container') && el.dataset.container === 'buy' &&
    el.dataset.hasOwnProperty('use') && el.dataset.use === 'report') {
    e.preventDefault()
    // Prepare module data
    let productId = (el.dataset.hasOwnProperty('productId')) ? parseInt(el.dataset.productId, 10) : false
    eventEmitter.emit('reportAvailability', { productId: productId })
  } else if (el.dataset.hasOwnProperty('container') && el.dataset.container === 'buy' &&
    el.dataset.hasOwnProperty('use') && el.dataset.use === 'pre-order') {
    e.preventDefault()
    // Prepare module data
    let productId = (el.dataset.hasOwnProperty('productId')) ? parseInt(el.dataset.productId, 10) : false
    eventEmitter.emit('preOrder', { productId: productId })
  }
})

// Product availability report modal
eventEmitter.on('reportAvailability', data => {
  if (!data.productId) {
    console.error('reportAvailability: product id not defined');
    return;
  }

  let reportAvailability = new ReportAvailability();
  // Set product
  reportAvailability.productId = data.productId;
  // Set data load url
  reportAvailability.url = '/local/templates/tehnoezh-modern/order_ajax.php';
  //Set lang id
  reportAvailability.langId = lang;
  // Load packets data
  reportAvailability.loadData({action: 'getMessages'}).then(response => {
    return response.json();
  }).then(messages => {
    // Set user inteface messages
    reportAvailability.messages = messages;
    // Set html body of popup window
    reportAvailability.body = reportAvailability.renderFields();
    // Init interface events
    reportAvailability.initEvents();
    // Show popup window
    reportAvailability.open();
  });
});

// Product pre order modal
eventEmitter.on('preOrder', data => {

  if (!data.productId) {
    console.error('preOrder: product id not defined')
    return
  }

  import(/* webpackChunkName: 'preOrder' */ '../preOrder').then(module => {
    let preOrder = new module.PreOrder()
    // Set product
    preOrder.productId = data.productId
    // Set data load url
    preOrder.url = '/local/templates/tehnoezh-modern/order_ajax.php'
    // Load packets data
    preOrder.loadData({ action: 'getMessages' })
      .then(response => {
        return response.json()
      })
      .then(messages => {
        // Set user inteface messages
        preOrder.messages = messages
        // Set html body of popup window
        preOrder.body = preOrder.renderFields()
        // Init interface events
        preOrder.initEvents()
        // Show popup window
        preOrder.open()
      })
  })
})

export
{
  eventEmitter, facebook, analytics, productWishlist, productCompare, sessid, lang, isAuth, userData, fuserId,
  currentCityId, currentCityName, userId, userEsputnikId, userName, userEmail, userPhone, dynRem, userBlock,
  esputnikTracker, axiosConfig, multisearchTracking, userCityData, axiosInstance
}
export { HeaderCallback } from '../header/phones'