import React, {useContext, useEffect, useRef, useState} from 'react';
import {
  ClientWidthContext, ModalContext, ModalDispatchContext,
} from '../Context/ApplicationContextProvider';
import {hoverBtnClass} from '../../common/baseData';
import Icon from '../Icon/Icon';
import Lang from '../Lang/Lang';
import SearchResult from '../SearchForm/SearchResult';
import SearchForm from '../SearchForm/SearchForm';
import UserData from '../UserData/UserData';
import UserDataDesktop from '../UserData/Desktop';

function SecondLineDesktop(props) {
  const {
    children,
    sticky,
    showTopBanner
  } = props;

  const dispatchModal = useContext(ModalDispatchContext);
  const modals = useContext(ModalContext);

  function closeMenuOnWrongTarget(event) {
    let relatedTarget = event.relatedTarget;

    if (relatedTarget.closest('#navigation-root') === null)
      dispatchModal({type: 'hide', modalType: 'navigation'});
  }

  return (
    <>
      <section className="block w-[295px]">
        <button
          onMouseEnter={() => {
            // активация навигации на странице
            dispatchModal({
              type: 'show',
              modalType: 'navigation',
            });
          }}
          onMouseLeave={event => (closeMenuOnWrongTarget(event))}
          className={`flex items-center justify-center gap-4 w-full h-[56px] bg-blue-primary border-0 rounded-2 ${hoverBtnClass}`}>
          <Icon name={'catalog-polygon-icon'} className="h-4 w-4"/>
          <span className="text-[16px] text-white leading-[24px]">
            {Lang.getMessage('catalog')}
          </span>
        </button>
      </section>
      <SearchForm type={'search'}>
        {
          modals.includes('search') && (
            <SearchResult
              inPageMobile={false}
              topPosition={sticky ? 70 : showTopBanner === true
                ? 168
                : 128}
              additionalClass={''}/>
          )
        }
      </SearchForm>
      {
        !modals.includes('search') && (
          <div className="flex items-center">
            <UserDataDesktop/>
          </div>
        )
      }
    </>
  );
}

export default SecondLineDesktop;